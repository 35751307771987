<template>
  <div v-show="showPage">
    <div style="height: 0; opacity: 0; overflow: hidden; min-width:790px; width:790px; max-width:unset;">
      <b-card
        id="pdfGenerateDiv"
        ref="pdfGenerateDivRef"
        no-body
        class="invoice-preview-card"
      >
        <div
          v-for="pg in pdfTotalPage"
          :key="pg"
        >
          <div
            class="card__inner"
            style="height: 1080px;"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row invoice-spacing mt-0">
                <div>
                  <div class="logo-wrapper">
                    <img
                      style="width:130px;"
                      src="/nirvana-memorial-garden-logo.png"
                      alt="Logo"
                    >
                  </div>
                </div>
                <div class="mt-md-0 mt-2 text-right">
                  <h4
                    class="invoice-title"
                    style="font-size: 18px;line-height: 21px;color: #104D9D; margin:0;"
                  >
                    {{ ncf.stringID }}
                  </h4>
                  <div class="invoice-date-wrapper">
                    <p
                      class="invoice-date"
                      style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                    >
                      <span class="semi-bold">Action Taken By</span> {{ ncf.createdBy ? ncf.createdBy.name : '' }}, {{ ncf.createdBy ? (ncf.createdBy.department.length ? ncf.createdBy.department[0].name : '') : '' }}
                    </p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p
                      class="invoice-date"
                      style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                    >
                      <span class="semi-bold">Created On:</span> {{ dateFormatWithTime(ncf.createdAt) }}
                    </p>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr
              class="invoice-spacing"
              style="margin-top:25px!important"
            >

            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0 main__body"
            >
              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col
                  v-if="pg == 1"
                  cols="12"
                  xl="12"
                  class="p-0"
                >
                  <h4 style="font-size:14px; text-transform: uppercase; color: #000000; font-weight: 900; margin:0 0 20px">
                    Non-conforming material disposal & Corrective action
                  </h4>
                  <h6
                    class="mb-2"
                    style="font-size:14px; line-height:21px; color:#000000; margin:0 0 10px;"
                  >
                    Non-Conformance Details
                  </h6>
                  <b-table-simple
                    borderless
                    responsive
                  >
                    <b-tbody>
                      <b-tr>
                        <b-th width="230px">
                          Type of Non-Conformance
                        </b-th>
                        <b-td>{{ ncf.type }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Material
                        </b-th>
                        <b-td>{{ ncf.itemName }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Vendor
                        </b-th>
                        <b-td>{{ ncf.vendor ? ncf.vendor.companyName: '' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Quantity
                        </b-th>
                        <b-td>{{ ncf.quantity ? ncf.quantity : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Nature of Defect
                        </b-th>
                        <b-td>{{ ncf.natureOfDefect ? ncf.natureOfDefect : '-' }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card-body>

            <hr
              class="invoice-spacing"
            >
            <b-card-body
              class="invoice-padding pt-0 main__body"
            >
              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col
                  cols="12"
                  xl="12"
                  class="p-0"
                >
                  <h6
                    class="mb-2"
                    style="font-size:14px; line-height:17px; font-weight:500; color:#000000; margin:0 0 10px"
                  >
                    Proposed Solution
                  </h6>
                  <p
                    class="text-bold"
                    style="font-size:11px; font-weight:600; line-height:16px; margin:0;"
                  >
                    Proposed Action
                  </p>
                  <span style="font-size:11px; font-weight:400; line-height:16px; margin:0 0 10px; display: block;">{{ ncf.proposedSolution }}</span>
                  <p
                    class="text-bold"
                    style="font-size:11px; font-weight:600; line-height:16px; margin:0;"
                  >
                    Remark
                  </p>
                  <span style="font-size:11px; font-weight:400; line-height:16px; margin:0 0 10px; display: block;">{{ ncf.remarks ? ncf.remarks : '-' }}</span>
                  <p
                    class="text-bold"
                    style="font-size:11px; font-weight:600; line-height:16px; margin:0;"
                  >
                    Corrective Action By External Provider
                  </p>
                  <span style="font-size:11px; font-weight:400; line-height:16px; margin:0 0 0px; display: block;">{{ ncf.correctiveAction ? ncf.correctiveAction : '-' }}</span>
                </b-col>
              </b-row>
            </b-card-body>

            <div
              v-if="pg == pdfTotalPage"
              style="position: relative; bottom: 0; width: 100%;"
            >
              <div :style="{ height: gapHeight+'px' }" />
              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0 footer__area">
                <b-row>

                  <!-- Col: Sales Persion -->
                  <b-col
                    v-for="(signatory, index) of ncf.signatories"
                    :key="index"
                    cols="3"
                    md="3"
                    class="mt-md-1 mt-1"
                    order="2"
                    order-md="1"
                  >
                    <b-card-text class="mb-0">
                      <span class="font-weight-bold">{{ index + 1 }}. {{ signatory.type }}</span>
                      <div class="sign-box">
                        <div v-if="signatory.signed == true">
                          <!-- <div v-if="signatory.user && signatory.user.signature">
                            <b-img
                              class="mb-1 mb-sm-0 user__avatar"
                              height="50"
                              :src="signatory.user.signature"
                            />
                          </div> -->
                          <div v-if="signatory.user && signatory.user.name">
                            <span>{{ signatory.user.name }}</span>
                          </div>
                        </div>
                        <div v-else>
                          <p>Pending Signature</p>
                        </div>
                      </div>
                      <p class="ml-75 semi-bold">
                        {{ signatory.user ? signatory.user.name : '' }}
                      </p>
                      <p class="ml-75">
                        {{ signatory.user ? (signatory.user.department.length ? signatory.user.department[0].name : '') : '' }}
                      </p>
                      <p class="ml-75">
                        {{ signatory.signed == true ? dateFormatWithTime(signatory.date) : '-' }}
                      </p>
                    </b-card-text>
                  </b-col>
                </b-row>
              </b-card-body>
            </div>
          </div>
          <!-- <div
            v-if="pg != pdfTotalPage"
            class="html2pdf__page-break"
          /> -->
        </div>
      </b-card>
      <b-card
        no-body
        class="invoice-preview-card"
      >
        <div
          class="card__inner"
          style="height: 1120px;"
        >
          <!-- Header -->
          <b-card-body
            id="main-header"
            class="invoice-padding pb-0"
          >

            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div class="logo-wrapper">
                <img
                  style="width:130px; height:auto;"
                  src="/nirvana-memorial-garden-logo.png"
                  alt="Logo"
                >
              </div>
              <div class="mt-md-0 mt-2 text-right">
                <h4
                  class="invoice-title"
                  style="font-size: 18px;line-height: 21px;color: #104D9D; margin:0;"
                >
                  {{ ncf.stringID }}
                </h4>
                <div class="invoice-date-wrapper">
                  <p
                    class="invoice-date"
                    style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                  >
                    <span class="semi-bold">Action Taken By</span> {{ ncf.createdBy ? ncf.createdBy.name : '' }}, {{ ncf.createdBy ? (ncf.createdBy.department.length ? ncf.createdBy.department[0].name : '') : '' }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p
                    class="invoice-date"
                    style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                  >
                    <span class="semi-bold">Created On:</span> {{ dateFormatWithTime(new Date()) }}
                  </p>
                </div>
              </div>
            </div>

            <hr
              class="invoice-spacing"
              style="margin-top:25px!important"
            >
          </b-card-body>

          <!-- Invoice Client & Payment Details -->
          <div id="content-div">
            <b-card-body
              class="invoice-padding pt-0 main__body"
            >
              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col
                  id="requester-header"
                  cols="12"
                  xl="12"
                  class="p-0"
                >
                  <h4 style="font-size:14px; text-transform: uppercase; color: #000000; font-weight: 900; margin:0 0 20px">
                    Quotation Comparison and Approval Form
                  </h4>
                  <h6
                    class="mb-2"
                    style="font-size:14px; line-height:21px; color:#000000; margin:0 0 10px;"
                  >
                    Non-Conformance Details
                  </h6>
                  <b-table-simple
                    borderless
                    responsive
                  >
                    <b-tbody>
                      <b-tr>
                        <b-th width="230px">
                          Type of Non-Conformance
                        </b-th>
                        <b-td>{{ ncf.type }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Material
                        </b-th>
                        <b-td>{{ ncf.itemName }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Vendor
                        </b-th>
                        <b-td>{{ ncf.vendor ? ncf.vendor.companyName : '' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Quantity
                        </b-th>
                        <b-td>{{ ncf.quantity ? ncf.quantity : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Nature of Defect
                        </b-th>
                        <b-td>{{ ncf.natureOfDefect ? ncf.natureOfDefect : '-' }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card-body>

            <hr
              class="invoice-spacing"
            >
            <b-card-body
              class="invoice-padding pt-0 main__body"
            >
              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col
                  cols="12"
                  xl="12"
                  class="p-0"
                >
                  <h6
                    class="mb-2"
                    style="font-size:14px; line-height:17px; font-weight:500; color:#000000; margin:0 0 10px"
                  >
                    Proposed Solution
                  </h6>
                  <p
                    class="text-bold"
                    style="font-size:11px; font-weight:600; line-height:16px; margin:0;"
                  >
                    Proposed Action
                  </p>
                  <span style="font-size:11px; font-weight:400; line-height:16px; margin:0 0 10px; display: block;">{{ ncf.proposedSolution }}</span>
                  <p
                    class="text-bold"
                    style="font-size:11px; font-weight:600; line-height:16px; margin:0;"
                  >
                    Remark
                  </p>
                  <span style="font-size:11px; font-weight:400; line-height:16px; margin:0 0 10px; display: block;">{{ ncf.remarks ? ncf.remarks : '-' }}</span>
                  <p
                    class="text-bold"
                    style="font-size:11px; font-weight:600; line-height:16px; margin:0;"
                  >
                    Corrective Action By External Provider
                  </p>
                  <span style="font-size:11px; font-weight:400; line-height:16px; margin:0 0 0px; display: block;">{{ ncf.correctiveAction ? ncf.correctiveAction : '-' }}</span>
                </b-col>
              </b-row>
            </b-card-body>
          </div>

          <!-- Invoice Description: Total -->
          <b-card-body
            id="footer-div"
            class="invoice-padding pb-0 footer__area"
          >
            <!-- Spacer -->
            <hr class="invoice-spacing">
            <b-row>

              <!-- Col: Sales Persion -->
              <b-col
                v-for="(signatory, index) of ncf.signatories"
                :key="index"
                cols="3"
                md="3"
                class="mt-md-1 mt-1"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">{{ index + 1 }}. {{ signatory.type }}</span>
                  <div class="sign-box">
                    <div v-if="signatory.signed == true">
                      <!-- <div v-if="signatory.user && signatory.user.signature">
                        <b-img
                          class="mb-1 mb-sm-0 user__avatar"
                          height="50"
                          :src="signatory.user.signature"
                        />
                      </div> -->
                      <div v-if="signatory.user && signatory.user.name">
                        <span>{{ signatory.user.name }}</span>
                      </div>
                    </div>
                    <div v-else>
                      <p>Pending Signature</p>
                    </div>
                  </div>
                  <p class="ml-75 semi-bold">
                    {{ signatory.user ? signatory.user.name : '' }}
                  </p>
                  <p class="ml-75">
                    {{ signatory.user ? (signatory.user.department.length ? signatory.user.department[0].name : '') : '' }}
                  </p>
                  <p class="ml-75">
                    {{ signatory.signed == true ? dateFormatWithTime(signatory.date) : '-' }}
                  </p>
                </b-card-text>
              </b-col>
            </b-row>
          </b-card-body>
        </div>
      </b-card>
    </div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col
            class="content-header-left mb-2"
            cols="12"
            md="12"
          >
            <b-row class="breadcrumbs-top">
              <b-col cols="12">
                <h1
                  class="custom-header-title"
                >
                  Preview Non-Conformance Form
                </h1>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <section class="invoice-preview-wrapper">
      <b-row class="invoice-preview">
        <!-- Col: Left (Invoice Container) -->
        <b-col
          cols="12"
          xl="9"
          md="8"
        >
          <div>
            <b-card
              class="mb-0"
            >
              <b-row class="invoice-preview">
                <b-col
                  cols="12"
                  xl="4"
                  md="4"
                  class="text-left"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="link"
                    :disabled="currentFileIndex == 1"
                    @click="changeFileIndex(currentFileIndex - 1)"
                  >
                    <feather-icon
                      icon="ChevronLeftIcon"
                      class="pt-25"
                      size="18"
                    />
                    <span class="align-middle">Prev</span>
                  </b-button>
                </b-col>
                <b-col
                  cols="12"
                  xl="4"
                  md="4"
                  class="text-center"
                >
                  <b-dropdown
                    id="dropdown-1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    split
                  >
                    <template #button-content>
                      <feather-icon
                        icon="FileIcon"
                        size="18"
                        class="align-middle mr-1"
                      />
                      <span class="mr-1">{{ currentFileIndex.toString().padStart(2, "0") }} / {{ ncf.attachments ? ((ncf.attachments.length + 1).toString().padStart(2, "0")) : '01' }}: {{ selectedFileName }}</span>
                    </template>
                    <b-dropdown-item @click="changeFileIndex(1)">
                      {{ ncf.stringID }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-for="(file, key) in ncf.attachments"
                      :key="key"
                      @click="changeFileIndex(key + 2)"
                    >
                      {{ file ? file.name : '' }}
                    </b-dropdown-item>
                  </b-dropdown>
                  <p
                    v-show="currentFileIndex == 1"
                    class="mt-1"
                  >
                    Non-Conformance Form (NCF)
                  </p>
                  <p
                    v-show="currentFileIndex != 1"
                    class="mt-1"
                  >
                    {{ ncf.attachments ? (ncf.attachments[currentFileIndex - 1] ? ncf.attachments[currentFileIndex - 1].description : '') : '' }}
                  </p>
                </b-col>
                <b-col
                  cols="12"
                  xl="4"
                  md="4"
                  class="text-right"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="link"
                    :disabled="currentFileIndex == (ncf.attachments ? (ncf.attachments.length + 1) : 1)"
                    @click="changeFileIndex(currentFileIndex + 1)"
                  >
                    <span class="align-middle">Next</span>
                    <feather-icon
                      icon="ChevronRightIcon"
                      class="pt-25"
                      size="18"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </div>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <div v-show="showAttachment">
              <iframe
                v-show="showPDF"
                id="attachmentpdf"
                width="100%"
                height="1145"
              />
              <b-img
                v-show="!showPDF"
                :src="imageSrc"
                fluid-grow
                alt="Fluid-grow image"
              />
            </div>
            <div v-show="!showAttachment">
              <iframe
                id="docpdf"
                width="100%"
                height="1145"
              />
            </div>
          </b-card>
        </b-col>

        <!-- Right Col: Card -->
        <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions"
        >
          <b-card v-if="user._id == (ncf.createdBy ? ncf.createdBy._id : '') && !$route.query.type">
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">Approval Required</span>
                Ready to Submit?
              </h3>
            </template>

            <b-card-text class="mb-0">
              <span>
                If the information on this Non-Conformance Form is accurate, please proceed to confirm to e-sign.
              </span>
              <br>
              <span>It will then be sent to the relevant parties for e-signing after.</span>
            </b-card-text>

            <b-form-checkbox
              v-model="secondAgree"
              value="accepted"
              unchecked-value=""
              class="custom-control-primary"
            >
              I confirm that all information is accurate on this PO.
            </b-form-checkbox>

            <!-- Button: Send Invoice -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mb-75"
              block
              :disabled="ncf.status == 'cancelled' || secondAgree == '' || ((ncf.signatories ? ncf.signatories[0].signed : '') == true)"
              @click="submitForApproval()"
            >
              <feather-icon
                icon="FeatherIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle">Sign and Submit NCF</span>
            </b-button>

            <!-- Button: DOwnload -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="flat-primary"
              class="mb-75"
              block
              :to="{ name: 'purchasing-ncf-index', params: { type: 'all-ncfs' } }"
            >
              Back
            </b-button>
          </b-card>
          <b-card v-else>
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">{{ approverHeader }}</span>
                {{ approverTitle }}
              </h3>
            </template>
            <b-card-text
              v-if="$route.query.type && $route.query.type == (ncf.signatories ? (ncf.signatories.length - 1) : 0)"
              class="mb-0"
            >
              <span class="text-bold">You are now reviewing this NCF as the signatory for "{{ approverType }}".</span>
              <br>
              <span>
                If the information on this NCF is accurate, e-sign this form to approve this NCF and close this NCF.
              </span>
            </b-card-text>
            <b-card-text
              v-else
              class="mb-0"
            >
              <span class="text-bold">You are now reviewing this NCF as the signatory for "{{ approverType }}".</span>
              <br>
              <span>If the information on this NCF is accurate, e-sign this form and it will be sent to the next assigned signatory ({{ nextApproverName }}) for signing.</span>
            </b-card-text>

            <b-form-checkbox
              v-model="secondAgree"
              value="accepted"
              unchecked-value=""
              class="custom-control-primary"
            >
              I confirm that all information is accurate on this PO.
            </b-form-checkbox>

            <!-- Button: Send Invoice -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mb-75"
              block
              :disabled="ncf.status == 'cancelled' || secondAgree == '' || !canSubmit"
              @click="approveNCF()"
            >
              <feather-icon
                icon="FeatherIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle">Sign and Approve NCF</span>
            </b-button>

            <!-- Button: DOwnload -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="flat-primary"
              class="mb-75"
              block
              :to="{ name: 'purchasing-ncf-index', params: { type: 'all-ncfs' } }"
            >
              Back
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BImg, BCardText, VBTooltip, BTableSimple, BTbody,
  BTr, BTh, BTd, BFormCheckbox, BCardBody, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import html2pdf from 'html2pdf.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BImg,
    BCardText,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BFormCheckbox,
    BCardBody,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  // props: {
  //   purchaseRequest: {
  //     type: Object,
  //     required: true,
  //   },
  //   ncf: {
  //     type: Object,
  //     required: true,
  //   },
  // },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      showPage: false,
      canSubmit: false,
      ncf: {},
      comment: '',
      extraComment: '',
      approverType: '',
      approverHeader: '',
      approverTitle: '',
      nextApproverName: '',
      status: 'confirmed',
      statusValidation: false,
      selectedFileName: '',
      currentFileIndex: 1,
      pdfTotalPage: 0,
      elHeight: 0,
      gapHeight: 0,
      secondAgree: '',
      pdfDataJS: '',
      imageSrc: '',
      showPDF: true,
      showAttachment: false,
      showFirst: true,
    }
  },
  mounted() {
    this.$http.get(`purchase/ncf/${this.$route.params.id}/show`)
      .then(response => {
        this.ncf = response.data.data || {}
        this.selectedFileName = this.ncf.stringID
        if (this.$route.query.type) {
          let { type } = this.$route.query
          // eslint-disable-next-line radix
          type = parseInt(type)

          if (this.ncf.signatories[type].signed === true || this.resolveSignValidation(type) || this.ncf.signatories[type].user._id !== this.user._id) {
            this.$router.push({ name: 'page-not-found' })
          }
          if (type === (this.ncf.signatories.length - 1)) {
            this.approverType = this.ncf.signatories[type].type
            this.approverHeader = 'Signature Required'
            this.approverTitle = 'Approve This NCF?'
            this.nextApproverName = ''
          } else {
            this.approverType = this.ncf.signatories[type].type
            this.approverHeader = 'Signature Required'
            this.approverTitle = 'Approve This NCF?'
            this.nextApproverName = this.ncf.signatories[type + 1].user.name
          }
          this.canSubmit = true
        }
        this.showPage = true
        this.enablePreview()
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    resolveSignValidation(key) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < key; i++) {
        if (this.ncf.signatories[i].signed === false) return true
      }
      return false
    },
    changeFileIndex(key) {
      this.currentFileIndex = key
      if (key === 1) {
        this.selectedFileName = this.ncf.stringID
        this.showAttachment = false
      } else {
        this.selectedFileName = this.ncf.attachments[key - 2].name
        if (this.ncf.attachments[key - 2].type.includes('pdf')) {
          const pdfViewerFrame2 = document.getElementById('attachmentpdf')
          pdfViewerFrame2.setAttribute('src', `/lib/pdfjs/web/viewer.html?file=${this.ncf.attachments[key - 2].data}`)
          this.showPDF = true
        } else {
          this.imageSrc = this.ncf.attachments[key - 2].data
          this.showPDF = false
        }
        this.showAttachment = true
      }
    },
    base64ToUint8Array(base64) {
      const raw = atob(base64)
      const uint8Array = new Uint8Array(raw.length)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < raw.length; i++) {
        uint8Array[i] = raw.charCodeAt(i)
      }
      return uint8Array
    },
    async enablePreview() {
      store.commit('appConfig/UPDATE_LOADER', true)
      const vm = this
      await this.calculateTotalPage()

      // start of pdf using html2PDF package
      const element = document.getElementById('pdfGenerateDiv')
      const opt = {
        margin: 1,
        filename: 'myfile.pdf',
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'], after: '.page-section' },
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
          scale: 2,
          dpi: 192,
          // windowWidth: 1024,
          letterRendering: true,
        },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'p' },
      }

      html2pdf().set(opt).from(element).toPdf()
        .get('pdf')
        .then(pdf => {
          vm.pdfData = pdf.output('datauristring')
          const base64Raw = vm.pdfData.split(',')[1]
          const pdfDataJS2 = vm.base64ToUint8Array(base64Raw)
          this.pdfDataJS = pdfDataJS2
          const pdfViewerFrame = document.getElementById('docpdf')
          // eslint-disable-next-line func-names
          pdfViewerFrame.onload = () => {
            pdfViewerFrame.contentWindow.PDFViewerApplication.open(pdfDataJS2)
          }
          pdfViewerFrame.setAttribute('src', '/lib/pdfjs/web/viewer.html?file=')
          store.commit('appConfig/UPDATE_LOADER', false)
        })
      // end of pdf using html2PDF package
      // this.pdfData = data
      // this.POPreview = true
    },
    async calculateTotalPage() {
      await this.$nextTick()
      this.pdfTotalPage = 0
      const logoHeight = document.getElementById('main-header').offsetHeight
      const footerHeight = document.getElementById('footer-div').offsetHeight
      const dHeight = 1080
      this.pdfTotalPage = 1
      const finalDataHeight = document.getElementById('content-div').offsetHeight
      this.gapHeight = dHeight - (logoHeight + footerHeight + finalDataHeight)
      if (finalDataHeight >= (dHeight - (logoHeight + footerHeight))) {
        this.pdfTotalPage = 2
        this.gapHeight = dHeight - (logoHeight + footerHeight)
      }
    },
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },
    submitForApproval() {
      this.$swal({
        title: 'Submit this NCF?',
        html: `You are proceeding to sign this NCF. The NCF will then be ready for ${this.ncf.signatories[1] ? this.ncf.signatories[1].user.name : ''} for signing.`,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/fly.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Proceed!',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('url', window.location.origin)

            this.$http.post(`purchase/ncf/${this.$route.params.id}/requester/submit`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(() => {
                this.$swal({
                  title: 'Submitted for Approval',
                  html: `${this.ncf.stringID} will now be sent to the next assigned signatory (${this.ncf.signatories[1] ? this.ncf.signatories[1].user.name : ''}) for signing.`,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/success.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(confirm => {
                    if (confirm.value) {
                      this.$router.push({ name: 'purchasing-ncf-show', params: { id: this.$route.params.id } })
                    }
                  })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },

    approveNCF() {
      let index = this.$route.query.type
      // eslint-disable-next-line radix
      index = parseInt(index)
      const id = this.$route.query.user
      let firstSwalMessage = ''
      let secondSwalMessage = ''
      let secondSwalTitle = 'NCF Signed'
      if ((this.ncf.signatories.length - 1) === index) {
        firstSwalMessage = 'You are proceeding to sign this NCF. Following this, the ncf will be closed.<br>All relevant parties will receive a notification.'
        secondSwalTitle = 'NCF Signed'
        secondSwalMessage = `${this.ncf.stringID} has been signed and closed.<br>All relevant parties will receive a notification.`
      } else {
        firstSwalMessage = `You are proceeding to sign on this NCF. The NCF will then be sent to ${this.ncf.signatories[index + 1] ? this.ncf.signatories[index + 1].user.name : ''} for signing.`
        secondSwalMessage = `${this.ncf.stringID} will now be sent to the next assigned signatory (${this.ncf.signatories[index + 1] ? this.ncf.signatories[index + 1].user.name : ''}) for signing.`
      }
      // this.previewPage = true
      this.$swal({
        title: 'Sign this NCF?',
        html: firstSwalMessage,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/fly.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Proceed!',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('id', id)
            formData.append('url', window.location.origin)
            formData.append('index', index)
            formData.append('signatoryRequired', this.ncf.signatories.length)

            this.$http.post(`purchase/ncf/${this.$route.params.id}/approve-ncf`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(() => {
                this.$swal({
                  title: secondSwalTitle,
                  html: secondSwalMessage,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/success.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(confirm => {
                    if (confirm.value) {
                      this.$router.push({ name: 'purchasing-ncf-show', params: { id: this.$route.params.id } })
                    }
                  })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
